<template>
  <div class="decorated">
    <b-container id="registration-finished" class="main-container">
      <b-row>
        <b-col>
          <h1>MERCI POUR VOTRE INSCRIPTION !</h1>
          <p>
            Souhaitez-vous enregistrer un {{ altRegistration.text }}
            maintenant ?
          </p>
          <b-button
            :to="altRegistration.route"
            variant="dark"
            class="alter-registration"
            router-tag="button"
            >AJOUTER UN {{ altRegistration.label }}</b-button
          >
          <p>Ou accéder directement {{ nextAction.text }} ?</p>
          <b-button
            :to="nextAction.route"
            variant="light"
            class="use-evenir"
            router-tag="button"
            >{{ nextAction.label }}</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Routes } from '@/router.js'
import { mapGetters } from 'vuex'

export default {
  name: 'registration-finished',
  computed: {
    ...mapGetters(['canDoSearch', 'hasVehicles', 'hasChargingPoints']),
    altRegistration() {
      return this.hasVehicles
        ? {
            text: `${this.hasChargingPoints ? 'autre ' : ''}point de recharge`,
            label: 'POINT DE RECHARGE',
            route: Routes.REGISTER_PROVIDER
          }
        : {
            text: 'véhicule',
            label: 'POINT DE RECHARGE',
            route: Routes.REGISTER_DRIVER
          }
    },
    nextAction() {
      return this.canDoSearch
        ? {
            text: 'au service',
            label: 'UTILISER EVENIR !',
            route: Routes.SEARCH
          }
        : {
            text: 'à votre compte',
            label: 'MON COMPTE',
            route: Routes.ACCOUNT
          }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name == Routes.REGISTER_DRIVER.name ||
      from.name == Routes.REGISTER_PROVIDER.name
    ) {
      next()
    } else {
      next(Routes.HOME)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#registration-finished {
  text-align: center;

  h1 {
    font-weight: $font-weight-bold;
    font-size: 13px;
    margin-top: 94px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }

  .btn {
    margin: 20px auto;

    &.alter-registration {
      width: 168px;
      height: 82px;
      line-height: 24px;
    }

    &.use-evenir {
      width: 154px;
      height: 48px;
    }
  }
}
</style>
