var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "decorated" },
    [
      _c(
        "b-container",
        {
          staticClass: "main-container",
          attrs: { id: "registration-finished" }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h1", [_vm._v("MERCI POUR VOTRE INSCRIPTION !")]),
                  _c("p", [
                    _vm._v(
                      " Souhaitez-vous enregistrer un " +
                        _vm._s(_vm.altRegistration.text) +
                        " maintenant ? "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "alter-registration",
                      attrs: {
                        to: _vm.altRegistration.route,
                        variant: "dark",
                        "router-tag": "button"
                      }
                    },
                    [_vm._v("AJOUTER UN " + _vm._s(_vm.altRegistration.label))]
                  ),
                  _c("p", [
                    _vm._v(
                      "Ou accéder directement " +
                        _vm._s(_vm.nextAction.text) +
                        " ?"
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "use-evenir",
                      attrs: {
                        to: _vm.nextAction.route,
                        variant: "light",
                        "router-tag": "button"
                      }
                    },
                    [_vm._v(_vm._s(_vm.nextAction.label))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }